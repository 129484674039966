<template>
    <v-text-field label="Phone Number" :value="value.phone" @input="input_changed">
        <template v-slot:append-outer>
            <v-btn :href="'tel:' + value.phone">Call</v-btn>
            <v-btn @click="$emit('send_invite')">Send Invite</v-btn>
            <v-btn icon @click="$emit('delete_contact')"><v-icon color="red">mdi-close</v-icon></v-btn>
        </template>
    </v-text-field>
</template>

<script>
export default {
  name: "PhoneContact",
  props: ["value"],
  methods: {
    input_changed(event)
    {
      this.$emit('input',{'phone': event})
    }
  }
}
</script>

<style scoped>

</style>
